var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import NewsSearchContext from "../../../../application/Context/NewsSearchContext";
import { Loading } from "../../shared-components/Loading/Loading";
import { NotFound } from "./NotFound";
import { motion } from "framer-motion";
import { Text, Tag, DateContainer, NewsContainer, NewsTitle, ImageContainer } from "../../Styles/SharedStyledComponents";
import { Row } from "../../shared-components/Ui/Row";
import { sliceStringAfterMaxLength } from "../../../../../src/utils/Utils";
export var List = function () {
    var _a;
    var _b = React.useContext(NewsSearchContext), news = _b.news, newsIsLoading = _b.newsIsLoading, filtersIsLoading = _b.filtersIsLoading, categories = _b.categories, handleCategoryChange = _b.handleCategoryChange;
    var langCode = ((_a = document.getElementById("language-code")) === null || _a === void 0 ? void 0 : _a.value) || "en";
    var params = new URLSearchParams(window.location.search);
    var options = { year: "numeric", month: "long", day: "numeric" };
    var f = Intl.DateTimeFormat(langCode, options);
    React.useEffect(function () {
        if ((params.has("category")) && !filtersIsLoading && categories.length) {
            if (params.has("category")) {
                var category = categories.filter(function (e) { return e.id == params.get("category"); });
                if (category.length > 0) {
                    handleCategoryChange([{
                            "value": category[0].id,
                            "label": langCode == "en" ? category[0].nom_en : category[0].nom_fr
                        }]);
                }
            }
            window.history.replaceState(null, "", window.location.href.split("?")[0]);
        }
    }, [filtersIsLoading]);
    return newsIsLoading ? (_jsx(Loading, {})) : news.length > 0 ? (_jsx("div", __assign({ "data-display-res": "list" }, { children: news.map(function (newsItem, i) {
            return (_jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.3 }, exit: { opacity: 0 } }, { children: _jsx(NewsContainer, { children: _jsxs(Row, { children: [_jsx("div", __assign({ className: "col-md-5 ps-md-0" }, { children: _jsx("a", __assign({ href: "".concat(langCode == "fr" ? newsItem.slug_fr : newsItem.slug_en) }, { children: _jsx(ImageContainer, { children: _jsx("img", { src: newsItem.thumbnail_url ? newsItem.thumbnail_url : process.env.IMG_URL + "/static/vendors/images/default-Image-company.png", alt: "banner" }) }) })) })), _jsx("div", __assign({ className: "col-md-7" }, { children: _jsxs(Text, { children: [_jsx(DateContainer, { children: f.format(new Date((newsItem.date_creation).replace(/-/g, "/" + " "))) }), _jsx(NewsTitle, { children: _jsx("a", __assign({ href: "".concat(langCode == "fr" ? newsItem.slug_fr : newsItem.slug_en) }, { children: langCode == "fr" ? newsItem.titre_fr : newsItem.titre_en })) }), _jsx("p", { children: sliceStringAfterMaxLength(120, langCode === "fr" ? newsItem.description_fr : newsItem.description_en) }), _jsx("div", { children: newsItem.categorie.map(function (cat) {
                                                return (_jsx("a", __assign({ href: "/".concat(langCode, "/").concat(langCode == "en" ? "news/" : "nouvelles/", "?category=").concat(cat.id) }, { children: _jsx(Tag, { children: langCode == "fr" ? cat.nom_fr : cat.nom_en }) }), cat.id));
                                            }) })] }) }))] }) }, i) }), i));
        }) }))) : (_jsx(NotFound, {}));
};
