import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Thembnail } from "./Thumbnail";
import { List } from "./List";
import { useContext } from "react";
import NewsSearchContext from "../../../../application/Context/NewsSearchContext";
export var View = function () {
    var view = useContext(NewsSearchContext).view;
    return (_jsx(React.Fragment, { children: view === "thubmnails" ? _jsx(Thembnail, {}) : _jsx(List, {}) }));
};
