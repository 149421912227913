var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useContext } from "react";
import NewsSearchContext from "../../../../application/Context/NewsSearchContext";
import { Loading } from "../../shared-components/Loading/Loading";
import { NotFound } from "./NotFound";
import { motion } from "framer-motion";
import { CategoryTag } from "./View-styles";
export var Thembnail = function () {
    var _a = useContext(NewsSearchContext), news = _a.news, newsIsLoading = _a.newsIsLoading;
    React.useEffect(function () {
    }, []);
    return newsIsLoading ? (_jsx(Loading, {})) : news.length > 0 ? (_jsxs("div", __assign({ "data-display-res": "thumbs" }, { children: [_jsx("div", __assign({ className: "news-first-section" }, { children: _jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.3 }, exit: { opacity: 0 } }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-md-6" }, { children: _jsx("img", { src: "".concat(news[0].thumbnail), alt: "" }) })), _jsx("div", __assign({ className: "col-md-6" }, { children: _jsxs("div", __assign({ className: "i-cont" }, { children: [_jsx("div", __assign({ className: "date" }, { children: "".concat(news[0].date_creation) })), _jsx("div", __assign({ className: "title" }, { children: _jsxs("a", __assign({ href: "".concat(news[0].url) }, { children: [" ", news[0].titre, " "] })) })), _jsx("p", { children: (news[0].description) }), news[0].categorie.map(function (cat) {
                                            return (_jsx(CategoryTag, __assign({ className: "btn btn-secondary compact" }, { children: cat.nom }), cat.id));
                                        })] })) }))] })) }), news[0].id) })), _jsx("div", __assign({ className: "news-thumbs-section" }, { children: _jsx(motion.div, __assign({ initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 0.3 }, exit: { opacity: 0 } }, { children: _jsx("div", __assign({ className: "row" }, { children: news.slice(1).map(function (newsItem) {
                            return (_jsxs("div", __assign({ className: "col-md-4" }, { children: [_jsx("div", __assign({ className: "card-thumb" }, { children: _jsx("img", { src: newsItem.image, alt: newsItem.titre }) })), _jsxs("div", __assign({ className: "i-cont" }, { children: [_jsxs("div", __assign({ className: "date" }, { children: [newsItem.date, " "] })), _jsx("div", __assign({ className: "title" }, { children: _jsxs("a", __assign({ href: "".concat(newsItem.url) }, { children: [" ", newsItem.titre, " "] })) })), _jsx("p", { children: newsItem.description }), newsItem.categorie.map(function (cat) {
                                                return (_jsx(CategoryTag, __assign({ className: "btn btn-secondary compact" }, { children: cat.nom }), cat.id));
                                            })] }))] }), newsItem.id));
                        }) })) })) }))] }))) : (_jsx(NotFound, {}));
};
