var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import NewsSearchContext from '../../../application/Context/NewsSearchContext';
import { CleanFilterBtn } from '../doc-lib-components/Views/Filters-styles';
import { useTranslation } from "react-i18next";
export var CleanFilters = function () {
    var t = useTranslation(['news']).t;
    var _a = React.useContext(NewsSearchContext), resetFilters = _a.resetFilters, filtersIsLoading = _a.filtersIsLoading;
    return (!filtersIsLoading ? _jsxs(CleanFilterBtn, __assign({ onClick: resetFilters }, { children: [" ", t("Effacer les filtres")] })) : _jsx("div", { className: "spinner-border spinner-border-sm", role: "status" }));
};
